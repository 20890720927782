exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-1-introduction-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/1-getting-started/1-introduction.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-1-getting-started-1-introduction-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-cli-1-cli-setup-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-cli/1-cli-setup.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-cli-1-cli-setup-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-cli-2-cli-reference-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-cli/2-cli-reference.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-cli-2-cli-reference-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-cli-3-cli-release-notes-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/2-cli/3-cli-release-notes.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-2-cli-3-cli-release-notes-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-iot-app-development-1-building-your-first-iot-app-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/3-iot-app-development/1-building-your-first-iot-app.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-iot-app-development-1-building-your-first-iot-app-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-iot-app-development-2-communication-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/3-iot-app-development/2-communication.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-iot-app-development-2-communication-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-iot-app-development-3-direct-deploy-and-debug-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/3-iot-app-development/3-direct-deploy-and-debug.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-iot-app-development-3-direct-deploy-and-debug-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-iot-app-development-4-remote-method-and-event-calls-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/3-iot-app-development/4-remote-method-and-event-calls.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-3-iot-app-development-4-remote-method-and-event-calls-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-4-screen-app-development-1-building-your-first-screen-app-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/4-screen-app-development/1-building-your-first-screen-app.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-4-screen-app-development-1-building-your-first-screen-app-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-4-screen-app-development-2-communicate-with-your-app-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/4-screen-app-development/2-communicate-with-your-app.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-4-screen-app-development-2-communicate-with-your-app-mdx" */),
  "component---src-templates-doc-template-js-content-file-path-src-content-docs-4-screen-app-development-3-test-events-locally-mdx": () => import("./../../../src/templates/docTemplate.js?__contentFilePath=/home/vsts/work/1/s/src/content/docs/4-screen-app-development/3-test-events-locally.mdx" /* webpackChunkName: "component---src-templates-doc-template-js-content-file-path-src-content-docs-4-screen-app-development-3-test-events-locally-mdx" */)
}

